/* eslint-disable no-unused-vars */
import fetch from 'isomorphic-fetch' // DO NOT REMOVE - we need to polyfill headers and importing at top of client, wrapped around application, allows us to do this
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { RestLink } from 'apollo-link-rest'
import { RetryLink } from 'apollo-link-retry'
import introspectionQueryResultData from './fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const host = process.env.GATSBY_CONTENTFUL_PREVIEW_HOST
const spaceID = process.env.GATSBY_CONTENTFUL_SPACE_ID

const uriNoToken = `https://${host}/spaces/${spaceID}`

const restLink = new RestLink({
  uri: uriNoToken,
  credentials: 'same-origin',
  responseTransformer: async response =>
    response.json().then(data => {
      if (data && data.items) {
        let res = {
          items: data.items,
          includes: data.includes,
        }
        return res
      } else {
        let res = {
          id: data.sys.id,
          contentType: data.sys.contentType
            ? data.sys.contentType.sys.id
            : 'asset',
          fields: data.fields,
        }
        return res
      }
    }),
})

const link = ApolloLink.from([
  new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => !!error,
    },
  }),
  new RestLink({
    uri: uriNoToken,
    credentials: 'same-origin',
    responseTransformer: async response =>
      response.json().then(data => {
        if (data && data.items) {
          let res = {
            items: data.items,
            includes: data.includes,
          }
          return res
        } else {
          let res = {
            id: data.sys.id,
            contentType: data.sys.contentType
              ? data.sys.contentType.sys.id
              : 'asset',
            fields: data.fields,
          }
          return res
        }
      }),
  }),
])

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({ fragmentMatcher }),
})
