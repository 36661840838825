/* eslint no-unused-expressions: 0 */
import './src/fonts.css'
import ReactDOM from 'react-dom'
export { wrapRootElement } from './apollo/wrap-root'

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
  }
}

// commenting out for now

// export const replaceHydrateFunction = () => {
//   return (element, container, callback) => {
//     ReactDOM.render(element, container, callback)
//   }
// }
